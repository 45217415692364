/*
 * LivingLogic stuff
 * project specific javascript
 * Copyright 2013, LivingLogic AG
 */

if (typeof(window.llprod) === 'undefined') {
	llprod = {};
}

llprod.MissingCookieWarning = $.extend(
	Utils.clone(LLObject),
	{
		init: function (options) {
			var self = LLObject.create.call(this);
			var isLoginPage = $("body").hasClass("pageStyle_login");

			self.options = {
				$loginForm: $(".login-form"),
				$warningContainer: $(".js--cookie-warning")
			};

			if (typeof(options) !== 'undefined') {
				$.extend(true, self.options, options);
			}

			if (!isLoginPage) {
				return false;
			}

			if (Modernizr.cookies) {
				return false;
			}

			self.handle();
			return self;
		},
		handle: function() {
			var self = this;
			var $loginForm = $(".login-form");

			self.options.$loginForm.children().hide();
			self.options.$warningContainer.show();

		}
	}
);

llprod.newMemberAutofill = function() {
	var k, params, field;
	if (XIST4C_GLOBALS && (XIST4C_GLOBALS.meta.coID === 47 || XIST4C_GLOBALS.meta.coID === 80)){ // Check if register page
		params = Utils.getUrlParamsAsJson();
		if (params){
			for (k in params) {
				$('input[name="' + k + '"]').val(params[k].replace(/\+/g, ' '));
			}
		}
	}
};


// Some starters
ll.$(function () {
	// Keep session alive.
	Utils.startPinging(300);

	// Tabbar Blueprint
	if($('#tabs').length) {
		new CBPFWTabs(document.getElementById('tabs'));
	}

	// Check if cookies are available and show the a notification
	llprod.MissingCookieWarning.init();

	// Autofill registration formular if user comes from mail invitation link.
	llprod.newMemberAutofill();
});
