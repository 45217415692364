import Vue from 'vue';

(function ($, window, document, undefined) {
	if( $('.data-actions').length ) {
		var dataactions = new Vue({
			el: '.data-actions',
			mounted: function() {
				if(this.uuid) {
					this.fetchDataActions(this.uuid);
				} else if(this.dat_id) {
					this.fetchDataActions(null, this.dat_id);
				}
				this.bindPopupHandlers();
			},
			data: {
				loading: false,
				submitted: false,
				uuid: $('.data-actions #uuid').val(),
				dat_id: $('.data-actions #dat_id').val(),
				dataactions: []
			},
			methods: {
				fetchDataActions: function(uuid, dat_id) {
					var self = this;
					self.loading = true;
					$.getJSON('/gateway/dataactions', {
						'dat_id': dat_id
					}, function(data) {
						self.loading = false;
						self.dataactions = data;
					});
				},
				bindPopupHandlers: function() {
					$('.js-data-actions-link').each(function() {
						var TAttrs = {};
						var elm = $(this).get(0);
						TAttrs.url = elm.href;
						TAttrs.height = 'auto';
						TAttrs.width = 'auto';
						TAttrs.background = 'true';
						LL_XPopup.registerPopup(
							elm,
							'click',
							'WEBPAGE',
							'p_c',
							'c',
							0,
							0,
							TAttrs
						);
					});
				},
				executeDataAction: function(e, da_id, uuid, dat_id) {
					var self = this;
					e.preventDefault();
					self.submitted = true;
					$.post('/gateway/dataactions/' + da_id, {
						'dat_id': dat_id
					}, function(data) {
						self.submitted = false;
						if(data.status == 'success') {
							llprod.notificationRowHandler('success', data.message);
						} else if(data.status == 'error') {
							llprod.notificationRowHandler('error', data.message);
						}
					});
				}
			},
			updated: function() {
				this.bindPopupHandlers();
			}
		})
	}
})(ll.jQuery, window, document);