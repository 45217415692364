const responsiveNav = require('responsive-nav');

(function($, llprod, Utils) {
	llprod.MobileNavHandler = $.extend(
		Utils.clone(LLObject),
		{
			init: function (opts) {
				var o = LLObject.create.call(this);
				o.defaults = {
					target: $('#head'),
					level: "1"
				};
				if (typeof(opts) !== 'undefined') {
					$.extend(true, o.defaults, opts);
				}
				o.handle();
				return o;
			},
			handle: function () {
				var target, level;
				target = this.getDefAttr('target', null);
				level = this.getDefAttr('level', null);
				if (target && target.length && level) {
					if (target.find('.nav_' + level).length) {
						this.prepareNav(target, level);
						this.createNav(level);
					}
				}
			},
			prepareNav: function (target, level) {
				var self = this;
				target.find('.nav_' + level)
					.attr('id', 'jsFlNav' + level)
					.addClass('jsFlNav' + level)
					.before(this.getToggle(target, level));
			},
			getToggle: function (target, level) {
				var toggler, txt = '', icon = '<i class="font-icon font-icon-menu"></i>';
				if (level === "2") {
					// txt = "Menü";
					txt = this.getNavPrompt(target, level);
					if (txt === '') {
						txt = "Menü";
					}
					icon = Utils.pixel().addClass('icon').wrap('<div/>').parent().html();
				}
				toggler = $(
					'<a href="#" aria-hidden="true" class="nav-toggle nav-toggle' + level + '" id="nav-toggle' + level + '">' +
						txt +
						icon +
					'</a>'
				);
				return toggler;
			},
			getNavPrompt: function (target, level) {
				return target.find('.flNavEl_' + level + '_inPath, .flNavEl_' + level + '_here').eq(0).text();
			},
			createNav: function (level) {
				var self = this;
				return responsiveNav('#jsFlNav' + level, self.navDefaults(level, '#nav-toggle' + level));
			},
			navDefaults: function (level, toggleID) {
				var d, tID, l;
				l = (level ? level : "1");
				tID = (toggleID ? toggleID : "");
				d = {
					animate: true, // Boolean: Use CSS3 transitions, true or false
					transition: 400, // Integer: Speed of the transition, in milliseconds
					label: "", // String: Label for the navigation toggle
					insert: "before", // String: Insert the toggle before or after the navigation
					customToggle: tID, // Selector: Specify the ID of a custom toggle
					openPos: "absolute", // String: Position of the opened nav, relative or static
					navClass: "nav-" + level + "-collapse", // String: Default CSS class. If changed, you need to edit the CSS too!
					jsClass: "js", // String: 'JS enabled' class which is added to <html> el
					init: function () {}, // Function: Init callback
					open: function () {}, // Function: Open callback
					close: function () {} // Function: Close callback
				};
				return d;
			}
		}
	);
})(ll.$, window.llprod, window.Utils);