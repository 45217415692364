// link the outer element. Use first link found inside.
(function($, llprod, Utils) {
	llprod.LinkOuterElement = $.extend(
		Utils.clone(LLObject),
		{
			init: function (opts) {
				var o = LLObject.create.call(this);
				o.defaults = {
					target: null
				};
				if (typeof(opts) !== 'undefined') {
					$.extend(true, o.defaults, opts);
				}
				o.handle();
				return o;
			},
			handle: function () {
				var target, self = this;
				target = this.getDefAttr('target', null);
				if (target && target.length) {
					target.each(function (i) {self.findAndLink($(this)); });
				}
			},
			findAndLink: function (elm) {
				var link;
				link = elm.find('a').eq(0).attr('href');
				if (link) {
					elm.css('cursor', 'pointer').bind('click.LinkOuterElement', function (e) {
						// Prevent click event from getting fired if
						if ($(e.target).parents("[data-contextmenu]").length === 0 && $(e.target).parents("#contextmenu").length === 0 && $(e.target).parents(".favorite-button").length === 0) {
							window.location = link;
							return false;
						}
					});
				}
			}
		}
	);
})(ll.$, window.llprod, window.Utils);