(function($, window, document, undefined) {
	'use strict';

	var raf = window.requestAnimationFrame;

	/**
	 * @type {{calculate: string}}
	 */
	var defaultOptions = { calculate: 'width' };

	/**
	 * @param {jQuery} $el
	 * @param {{calculate: string}} options
	 * @param {boolean=} forceRendering
	 * @return {jQuery}
	 */
	var resize = function($el, options, forceRendering) {
		var resizeFunction;
		if (options.calculate === 'height') {
			var width = $el.width();
			resizeFunction = function() {
				$el.height(width);
			};
		} else {
			var height = $el.height();
			resizeFunction = function() {
				$el.width(height);
			};
		}

		if (forceRendering) {
			resizeFunction();
		} else {
			raf(resizeFunction);
		}

		return $el;
	};

	/**
	 * @param {jQuery} $els
	 * @param {{calculate: string}} options
	 * @param {boolean=} forceRendering
	 * @return {jQuery}
	 */
	var resizeAll = function($els, options, forceRendering) {
		return $els.each(function() {
			resize($(this), options, forceRendering);
		});
	};


	/**
	 * @param {{calculate: string}} options
	 * @return {jQuery}
	 */
	$.fn.equalizeDimensions = function(options) {
		options = $.extend({}, defaultOptions, options);

		var $boxes = $(this);

		$(window).on('resize', function() {
			resizeAll($boxes, options);
		});

		return resizeAll($boxes, options, true);
	};
}(ll.$, window, document));
