/**
 * Service Worker
 */

// require('offline-plugin/runtime').install();


/**
 * Set Public Path
 */

/* jshint ignore:start */
if(typeof XIST4C_GLOBALS.uplPath !== 'undefined') {
	__webpack_public_path__ = XIST4C_GLOBALS.uplPath;
} else {
	console.warn(`__webpack_public_path__ can't be set, because XIST4C_GLOBALS.uplPath is undefined.`);
}
/* jshint ignore:end */


/**
 * Stylesheets
 */

require('./scss/application.scss');


/**
 * Import Dependencies
 */

import $ from 'jquery';

// Set js instead no-js in head of document
document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/,'js');

// Import Modernizr Custom Build
import Modernizr from 'modernizr';

// Import UL4
import ul4 from 'ul4/ul4.min';

// Import Vue
import Vue from 'vue';
import VueRouter from 'vue-router';

import 'jquery-sticky';
import 'jquery-match-height';
import 'magnific-popup';
import 'block-ui';
import 'moment';
import 'autosize';
import 'file-saver';
import 'iframe-resizer/js/iframeResizer';
import 'blueimp-file-upload';

Vue.config.productionTip = false;


// require('foundation-sites/js/foundation/foundation.js'); // foundation's base functions
// require('foundation-sites/js/foundation/foundation.interchange.js'); // responsive images
// require('foundation-sites/js/foundation/foundation.equalizer.js'); // create equal height content
// require('foundation-sites/js/foundation/foundation.topbar.js');
// require('./js/vendor/foundation/foundation.topbar.custom.js');


/**
 * Init Main Stuff
 */

if (typeof(window.llprod) === 'undefined') {
	window.llprod = {};
}

if (typeof(window.lltools) === 'undefined') {
	window.lltools = {};
}

if (typeof(window.ul4) === 'undefined') {
	window.ul4 = ul4.ul4;
}

if (typeof(window.Modernizr) === 'undefined') {
	window.Modernizr = Modernizr;
}


/**
 * Init momentjs
 */

import moment from 'moment/moment';
import 'moment/locale/de';

ll.$(function () {
	var lang = $.lang() || 'de';
	moment.locale(lang);
});


/**
 * Import Components
 */

// require('./js/base/breakpoints.js');
require('./js/vendors/onesignal');
require('./js/customer.js'); // init callings and your own javascript
