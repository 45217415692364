import '../base/llprod-globals';

(function($, llprod) {
	if (typeof(llprod) === 'undefined') {
		llprod = {};
	}

	// llprod.globals.texts.__preloadcache__([
	// 	'appbuilder_wizard_notifications_register_message',
	// 	'appbuilder_wizard_notifications_register_accept',
	// 	'appbuilder_wizard_notifications_register_cancel'
	// ]);

	if( $('#selenium-usermenu-logout-link') && $('#selenium-usermenu-logout-link').length ) {
		window.OneSignal = window.OneSignal || [];

		function getSubscriptionState() {
			return Promise.all([
				OneSignal.isPushNotificationsEnabled(),
				OneSignal.isOptedOut()
			]).then(function(result) {
				var isPushEnabled = result[0];
				var isOptedOut = result[1];

				return {
					isPushEnabled: isPushEnabled,
					isOptedOut: isOptedOut
				};
			});
		};

		OneSignal.push(["init", {
			appId: "684df999-7b29-4c4b-90e6-3d5c6c46a445",
			autoRegister: false,
			notifyButton: {
				enable: false
			},
			welcomeNotification: {
				disable: true
			},
			promptOptions: {
				/* actionMessage limited to 90 characters */
				actionMessage: "We'd like to show you notifications for the latest news and updates.",
				/* acceptButtonText limited to 15 characters */
				acceptButtonText: "ALLOW",
				/* cancelButtonText limited to 15 characters */
				cancelButtonText: "NO THANKS"
			}
		}]);

		OneSignal.push(function() {
			if (!OneSignal.isPushNotificationsSupported()) {
				return;
			}

			// OneSignal.showHttpPrompt();

			OneSignal.on('subscriptionChange', (isSubscribed) => {
				console.log('is-subscribed-to-push', isSubscribed);
			});

			getSubscriptionState().then(function(state) {
				if (state.isPushEnabled) {
					/* Subscribed, opt them out */
					// OneSignal.setSubscription(false);
				} else {
					if (state.isOptedOut) {
						/* Opted out, opt them back in */
						OneSignal.setSubscription(true);
					} else {
						/* Unsubscribed, subscribe them */
						OneSignal.registerForPushNotifications();
						OneSignal.setSubscription(true);
					}
					if($('.usermenu .account') && $('.usermenu .account').length) {
						OneSignal.setEmail( $('.usermenu .account').text() );
					}
				}
			});
		});
	}
})(ll.$, window.llprod);
