import './vendors/foundation/foundation';
import './vendors/foundation/foundation.forms';
import './vendors/foundation/foundation.interchange';
import './vendors/foundation/foundation.abide';
import './vendors/foundation/foundation.dropdown';
import './vendors/foundation/foundation.tooltips';
import './vendors/foundation/custom/foundation.menubar';

import './vendors/cbpFWTabs';
import './vendors/jquery.autosize.input';
import './vendors/jquery.ui.touch-punch.min';

import './base/ll-main';
import './base/ll-user-area';

import './components/equalize-dimensions';
import './components/responsive-tables';
import './components/select-text';
import './components/char-limit';
import './components/data-actions';
import './components/attachments';
import './components/mobile-nav-handler';


/**
 * Overwrite default LightboxHandler to use Magnific Popup
 */

import 'magnific-popup';
import './components/magnific-popup-i18n';


ll.$(function(){
	// enables lightbox
	window.LL_RelManager = new LL_RelationshipManager();
	window.LL_RelManager.addRelHandler(new XPopupHandler());
	window.LL_RelManager.addRelHandler(new LightboxHandler());
	window.LL_RelManager.addRelHandler(new XPopupTriggerHandler());

	// Only enable if flyoutNav (flOuterShell) is used
	// import '../js/components/mobileNavHandler';
	// if ((typeof(Modernizr) !== "undefined") && Modernizr.touch && (navigator.userAgent.toLowerCase().indexOf("android") > -1)) {
	// 	llprod.mobileNavHandler.init();
	// }

	// import './components/menuButtonHandler';
	// llprod.menuButtonHandler('.menu-button', '.top-bar');

	// mobile nav starter
	llprod.MobileNavHandler.init({'level': '1'});

	ll.$(document).foundation();
	ll.$(document).foundation('abide', {patterns: {password : /^[\w\W]{2,}$/}});
	ll.$(document).foundation('tooltips', {'disable-for-touch': true});
});
