/* ------------------------------------------------------------------ */
/* Magnific Popup German Translation                                  */
/* ------------------------------------------------------------------ */

import 'magnific-popup';

$(function() {
	var curLang = $('html').attr('lang');

	switch (curLang) {
		case 'de':
			$.extend(true, $.magnificPopup.defaults, {
				tClose: 'Schließen',
				tLoading: 'Wird geladen...',
				gallery: {
					tPrev: 'Zurück',
					tNext: 'Weiter',
					tCounter: '%curr% von %total%'
				},
				image: {
					tError: 'Das <a href="%url%">Bild</a> konnte nicht geladen werden.'
				},
				ajax: {
					tError: 'Der <a href="%url%">Inhalt</a> konnte nicht geladen werden.'
				}
			});

			break;
		default:
			// english already included
	}
});
